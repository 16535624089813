import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import { Link } from "react-router-dom";
import CallToActionIcon from '@material-ui/icons/CallToAction';
import Assessment from '@material-ui/icons/Assessment';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import HomeIcon from '@material-ui/icons/Home';
import BillingIcon from '@material-ui/icons/ShoppingBasket';
import PurchaseIcon from '@material-ui/icons/ShoppingCart';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CustomerIcon from '@material-ui/icons/SupervisorAccount';
import StockIcon from '@material-ui/icons/AddShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import MoneyIcon from '@material-ui/icons/Money';
import StatementIcon from '@material-ui/icons/CompareArrows';
import SmsIcon from '@material-ui/icons/Sms';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import ListIcon from '@material-ui/icons/List';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CategoryIcon from '@material-ui/icons/Category';
import TheatersIcon from '@material-ui/icons/Theaters';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import AddCommentIcon from '@material-ui/icons/AddComment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import PaymentsIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BookIcon from '@material-ui/icons/Book';
import FilterIcon from '@material-ui/icons/FilterVintage';
import MasterIcon from '@material-ui/icons/Storage';
import DynamicFeed from '@material-ui/icons/Feedback';
import CloseIcon from '@material-ui/icons/Close';
import BadgeIcon from '@material-ui/icons/PermIdentity';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

import ArtTrack from '@material-ui/icons/ArtTrack';
import Book from '@material-ui/icons/Book';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import IconButton from '@material-ui/core/IconButton';

import './drawer.css';

const useStyles = {
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: 50,
  },
  iconColor: {
    color: '#757575',
  },
};

export default class TemporaryDrawer extends React.Component {

  componentDidMount() {
    if (this.props.from) {
      this.toggleDrawer('left', true)
    }
  }

  classes = useStyles;

  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  expandIcon = (less) => {
    return (less
      ? <ExpandLess style={this.classes.iconColor} />
      : <ExpandMore style={this.classes.iconColor} />);
  }

  toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ ...this.state, [side]: open });
  };

  openNewTab() {
    window.open("https://crm.strackit.com");
  }

  getMaster = () => {
    return (
      <>
        <Link to="/customers">
          <ListItem button key="Customers" style={this.classes.nested}>
            <ListItemIcon><CustomerIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        </Link>
        <Link to="/employees">
          <ListItem button key="Employees" style={this.classes.nested}>
            <ListItemIcon><BadgeIcon /></ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItem>
        </Link>
        <Link to="/stocks">
          <ListItem button key="Stocks" style={this.classes.nested}>
            <ListItemIcon><StockIcon /></ListItemIcon>
            <ListItemText primary="Stocks" />
          </ListItem>
        </Link>
        <Link to="/ledger">
          <ListItem button key="Ledger" style={this.classes.nested}>
            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItem>
        </Link>
        <Link to="/master-category">
          <ListItem button key="Master Category" style={this.classes.nested}>
            <ListItemIcon><ArtTrack /></ListItemIcon>
            <ListItemText primary="Master Category" />
          </ListItem>
        </Link>
        <Link to="/secondary-category">
          <ListItem button key="Secondary Category" style={this.classes.nested}>
            <ListItemIcon><Book /></ListItemIcon>
            <ListItemText primary="Secondary Category" />
          </ListItem>
        </Link>
      </>
    );
  }

  getInvoices = () => {
    return (
      <>
        <Link to="/billing">
          <ListItem button key="Billing" style={this.classes.nested}>
            <ListItemIcon><BillingIcon /></ListItemIcon>
            <ListItemText primary="Billing" />
          </ListItem>
        </Link>
        <Link to="/stocks">
          <ListItem button key="Stock Management" style={this.classes.nested}>
            <ListItemIcon><StockIcon /></ListItemIcon>
            <ListItemText primary="Stock Management" />
          </ListItem>
        </Link>
        <Link to="/purchases">
          <ListItem button key="Purchase Details" style={this.classes.nested}>
            <ListItemIcon><PurchaseIcon /></ListItemIcon>
            <ListItemText primary="Purchase Details" />
          </ListItem>
        </Link>
        <Link to="/customers">
          <ListItem button key="Customers" style={this.classes.nested}>
            <ListItemIcon><CustomerIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        </Link>

      </>
    );
  }

  getAccounts = () => {
    return (
      <>
        <Link to="/customers">
          <ListItem button key="Customers" style={this.classes.nested}>
            <ListItemIcon><CustomerIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        </Link>
        <Link to="/bank-details">
          <ListItem button key="Bank Details" style={this.classes.nested}>
            <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
            <ListItemText primary="Bank Details" />
          </ListItem>
        </Link>
        <Link to="/ledger">
          <ListItem button key="Ledger" style={this.classes.nested}>
            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItem>
        </Link>
        <Link to="/statements">
          <ListItem button key="Statement" style={this.classes.nested}>
            <ListItemIcon><StatementIcon /></ListItemIcon>
            <ListItemText primary="Statement" />
          </ListItem>
        </Link>
        <Link to="/ledger-transaction">
          <ListItem button key="Ledger Transaction" style={this.classes.nested}>
            <ListItemIcon><MoneyIcon /></ListItemIcon>
            <ListItemText primary="Ledger Transaction" />
          </ListItem>
        </Link>
        <Link to="/monthly-expenses">
          <ListItem button key="Ledger" style={this.classes.nested}>
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="Monthly Expenses" />
          </ListItem>
        </Link>
      </>
    )
  }

  getEmployees = (style) => {
    return (
      <>
        <Link to="/employees/designations">
          <ListItem button key="Designations" style={style}>
            <ListItemIcon><NaturePeopleIcon /></ListItemIcon>
            <ListItemText primary="Designations" />
          </ListItem>
        </Link>
        <Link to="/employees">
          <ListItem button key="Employees" style={style}>
            <ListItemIcon><MasterIcon /></ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItem>
        </Link>
        <Link to="/employees/attendance">
          <ListItem button key="Attendance" style={style}>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary="Attendance" />
          </ListItem>
        </Link>
        <Link to="/employees/accounts">
          <ListItem button key="Accounts" style={style}>
            <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
        </Link>
        <Link to="/employees/holidays">
          <ListItem button key="Holidays" style={style}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Holidays" />
          </ListItem>
        </Link>
      </>
    );
  }

  getShoppingSite = () => {
    return (
      <>
        <Link to="/stocks">
          <ListItem button key="Stock Management" style={this.classes.nested}>
            <ListItemIcon><StockIcon /></ListItemIcon>
            <ListItemText primary="Stock Management" />
          </ListItem>
        </Link>
        <Link to="/master-category">
          <ListItem button key="Master Category" style={this.classes.nested}>
            <ListItemIcon><ArtTrack /></ListItemIcon>
            <ListItemText primary="Master Category" />
          </ListItem>
        </Link>
        <Link to="/secondary-category">
          <ListItem button key="Secondary Category" style={this.classes.nested}>
            <ListItemIcon><Book /></ListItemIcon>
            <ListItemText primary="Secondary Category" />
          </ListItem>
        </Link>
        <Link to="/banner">
          <ListItem button key="Banner" style={this.classes.nested}>
            <ListItemIcon><ViewCarouselIcon /></ListItemIcon>
            <ListItemText primary="Banner" />
          </ListItem>
        </Link>
        <Link to="/category-alignment">
          <ListItem button key="Category Alignment" style={this.classes.nested}>
            <ListItemIcon><CategoryIcon /></ListItemIcon>
            <ListItemText primary="Category Alignment" />
          </ListItem>
        </Link>
        <Link to="/product-enquiry">
          <ListItem button key="Product Enquiry" style={this.classes.nested}>
            <ListItemIcon><FormatIndentIncreaseIcon /></ListItemIcon>
            <ListItemText primary="Product Enquiry" />
          </ListItem>
        </Link>
        <Link to="/customer-enquiry">
          <ListItem button key="Customer Enquiry" style={this.classes.nested}>
            <ListItemIcon><AddCommentIcon /></ListItemIcon>
            <ListItemText primary="Customer Enquiry" />
          </ListItem>
        </Link>
        <Link to="/blog">
          <ListItem button key="Social Media" style={this.classes.nested}>
            <ListItemIcon><TheatersIcon /></ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItem>
        </Link>
        <Link to="/coupon-code">
          <ListItem button key="Coupon Code" style={this.classes.nested}>
            <ListItemIcon><LocalOfferIcon /></ListItemIcon>
            <ListItemText primary="Coupon Code" />
          </ListItem>
        </Link>
        <Link to="/order">
          <ListItem button key="Orders" style={this.classes.nested}>
            <ListItemIcon><BillingIcon /></ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
        </Link>
        <Link to="/cms">
          <ListItem button key="CMS" style={this.classes.nested}>
            <ListItemIcon><CallToActionIcon /></ListItemIcon>
            <ListItemText primary="CMS" />
          </ListItem>
        </Link>
        <Link to="/settings">
          <ListItem button key="Settings" style={this.classes.nested}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </Link>
      </>
    );
  }

  getReports = () => {
    return (
      <>
        <Link to="/reports">
          <ListItem button key="Reports" style={this.classes.nested}>
            <ListItemIcon><Assessment /></ListItemIcon>
            <ListItemText primary="Sales and Profit" />
          </ListItem>
        </Link>
        <Link to="/reports/performance">
          <ListItem button key="Performance Reports" style={this.classes.nested}>
            <ListItemIcon><FilterIcon /></ListItemIcon>
            <ListItemText primary="Performance" />
          </ListItem>
        </Link>
        <Link to="/gst-report">
          <ListItem button key="GST" style={this.classes.nested}>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="GST" />
          </ListItem>
        </Link>
        <Link to="/feedback-report">
          <ListItem button key="FeedBacks" style={this.classes.nested}>
            <ListItemIcon><DynamicFeed /></ListItemIcon>
            <ListItemText primary="FeedBack" />
          </ListItem>
        </Link>
      </>
    )
  }

  getExtras = () => {
    return (
      <>
        <ListItem button key="CRM"
          onClick={this.openNewTab.bind(this)}
          style={this.classes.nested}>
          <ListItemIcon><PermDataSettingIcon /></ListItemIcon>
          <ListItemText primary="CRM" />
        </ListItem>
        <Link to="/sms">
          <ListItem button key="GST Report" style={this.classes.nested}>
            <ListItemIcon><SmsIcon /></ListItemIcon>
            <ListItemText primary="SMS" />
          </ListItem>
        </Link>
        <Link to="/pns-service">
          <ListItem button key="PNS Service" style={this.classes.nested}>
            <ListItemIcon><SendIcon /></ListItemIcon>
            <ListItemText primary="PNS SERVICE" />
          </ListItem>
        </Link>
        <Link to="/prime">
          <ListItem button key="PRIME" style={this.classes.nested}>
            <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
            <ListItemText primary="PRIME" />
          </ListItem>
        </Link>
      </>
    );
  }

  getTransportationAndServices = (side) => {
    return (
      <div className={this.classes.list} role="presentation" onClick={this.toggleDrawer(side, false)} onKeyDown={this.toggleDrawer(side, false)}>
        <List>
          <Link to="/"><ListItem button key="Home">
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="HOME" />
          </ListItem>
          </Link>
          <Link to="/logistics-billing"><ListItem button key="Logistics Billing">
            <ListItemIcon><BillingIcon /></ListItemIcon>
            <ListItemText primary="LOGISTICS BILLING" />
          </ListItem>
          </Link>
          <Link to="/logistics-customers"><ListItem button key="Customers">
            <ListItemIcon><CustomerIcon /></ListItemIcon>
            <ListItemText primary="CUSTOMERS" />
          </ListItem>
          </Link>
          <Link to="/logistics-customers-statement"><ListItem button key="Statement">
            <ListItemIcon><StatementIcon /></ListItemIcon>
            <ListItemText primary="CUSTOMER STATEMENT" />
          </ListItem>
          </Link>
          <Link to="/bank-details"><ListItem button key="Bank Details">
            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
            <ListItemText primary="BANK DETAILS" />
          </ListItem>
          </Link>
          <Link to="/ledger"><ListItem button key="Ledger">
            <ListItemIcon><AddBoxIcon /></ListItemIcon>
            <ListItemText primary="LEDGER" />
          </ListItem>
          </Link>
          <Divider />
          <Link to="/logistics-vendor"><ListItem button key="Customers">
            <ListItemIcon><CustomerIcon /></ListItemIcon>
            <ListItemText primary="VENDOR LEDGER" />
          </ListItem>
          </Link>
          <Link to="/logistics-vendor-statement"><ListItem button key="Statement">
            <ListItemIcon><StatementIcon /></ListItemIcon>
            <ListItemText primary="VENDOR STATEMENT" />
          </ListItem>
          </Link>

          <Link to="/monthly-expenses"><ListItem button key="Ledger">
            <ListItemIcon><AddToPhotosIcon /></ListItemIcon>
            <ListItemText primary="MONTHLY EXPENSES" />
          </ListItem>
          </Link>
        </List>
      </div>
    );
  }

  sideList = side => {
    let host = window.location.hostname;
    if (host.includes("employees.strackit.com")) {
      return this.getEmployees(null);
    }
    if (this.props.shopDetails.category === 'Transportation And Services') {
      return this.getTransportationAndServices(side);
    }
    else {
      return (
        <div className={this.classes.list} role="presentation" id="main-drawer">
          <List>
            <Link to="/"><ListItem button key="Home">
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            </Link>

            <ListItem button onClick={
              () => this.setState({ masterOpen: !this.state.masterOpen })
            }>
              <ListItemIcon>
                <MasterIcon />
              </ListItemIcon>
              <ListItemText inset primary="Master" />
              {this.expandIcon(this.state.masterOpen)}
            </ListItem>
            <Collapse in={this.state.masterOpen} timeout="auto" unmountOnExit>
              {this.getMaster()}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ invoicesOpen: !this.state.invoicesOpen })
            }>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText inset primary="Invoicing" />
              {this.expandIcon(this.state.invoicesOpen)}
            </ListItem>
            <Collapse in={this.state.invoicesOpen} timeout="auto" unmountOnExit>
              {this.getInvoices()}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ accountsOpen: !this.state.accountsOpen })
            }>
              <ListItemIcon>
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText inset primary="Accounting" />
              {this.expandIcon(this.state.accountsOpen)}
            </ListItem>
            <Collapse in={this.state.accountsOpen} timeout="auto" unmountOnExit>
              {this.getAccounts()}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ employeesOpen: !this.state.employeesOpen })
            }>
              <ListItemIcon>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText inset primary="Employees" />
              {this.expandIcon(this.state.employeesOpen)}
            </ListItem>
            <Collapse in={this.state.employeesOpen} timeout="auto" unmountOnExit>
              {this.getEmployees(this.classes.nested)}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ shoppingOpen: !this.state.shoppingOpen })
            }>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText inset primary="ECommerce" />
              {this.expandIcon(this.state.shoppingOpen)}
            </ListItem>
            <Collapse in={this.state.shoppingOpen} timeout="auto" unmountOnExit>
              {this.getShoppingSite()}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ reportsOpen: !this.state.reportsOpen })
            }>
              <ListItemIcon>
                <Assessment />
              </ListItemIcon>
              <ListItemText inset primary="Reports" />
              {this.expandIcon(this.state.reportsOpen)}
            </ListItem>
            <Collapse in={this.state.reportsOpen} timeout="auto" unmountOnExit>
              {this.getReports()}
            </Collapse>

            <ListItem button onClick={
              () => this.setState({ extrasOpen: !this.state.extrasOpen })
            }>
              <ListItemIcon>
                <PlaylistAddIcon />
              </ListItemIcon>
              <ListItemText inset primary="Extras" />
              {this.expandIcon(this.state.extrasOpen)}
            </ListItem>
            <Collapse in={this.state.extrasOpen} timeout="auto" unmountOnExit>
              {this.getExtras()}
            </Collapse>

            <ListItem button key="Close Menu" onClick={this.toggleDrawer('left', false)}>
              <ListItemIcon><CloseIcon /></ListItemIcon>
              <ListItemText primary="Close Menu" />
            </ListItem>

          </List>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <IconButton onClick={this.toggleDrawer('left', true)}
          edge="start" color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Drawer open={this.state.left}
          variant="persistent"
          onClose={this.toggleDrawer('left', false)}>
          {this.sideList('left')}
        </Drawer>
      </div>
    );
  }
}
